@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Etna';
    src: local('Etna'), url(./resources/fonts/etna.otf) format('opentype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;


input:checked ~ .checkedBackground {
    background: #4391ff;
}

input:checked ~ .dotCheckbox {
    transform: translateX(28px);
    background-color: white;
}

/* For hiding stepper in input forms. */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    padding: 0 16px;
    line-height: inherit;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    padding: 0 16px;
    line-height: inherit;
}

input[type="checkbox"]:focus {
    outline: 0;
}

.background-blur {
    backdrop-filter: blur(20px);
}


.mjol-pulse-animation {
    animation: mjol-pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.inherit-border-radius {
    border-radius: inherit;
}

@keyframes mjol-pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1b5de3;
    color: #1b5de3;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1b5de3;
    color: #1b5de3;
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #1b5de3;
    color: #1b5de3;
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0.5s;
}

@keyframes dotFlashing {
    0% {
        background-color: #638eee;
    }
    50%,
    100% {
        background-color: #6fa7e1;
    }
}

.tooltip {
    border-radius: 11px !important;
}

.mjol-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: mjol-loader-frames 1s linear infinite;
}

@keyframes mjol-loader-frames {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}